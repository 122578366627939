import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Page.js";
import { Email } from '../components/Email';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`I. Kto sme a kde nás môžete kontaktovať`}</h2>
    <p>{`Webovú stránku www.dorucim.online (ďalej len „webová stránka“) prevádzkujú fyzické osoby: Tomáš Igrini, Ing. Roman Veselý, PhDr. Jarmila Lichvárová (ďalej len „prevádzkovateľ“).`}</p>
    <p>{`Kontakt: `}<Email mdxType="Email" /></p>
    <h2>{`II. Aké osobné údaje získavame a prečo ich zbierame`}</h2>
    <h3>{`Údaje pri vytvorení konta`}</h3>
    <p>{`V prípade, ak sa rozhodnete u nás vytvoriť používateľské konto, zbierame všetky údaje s tým súvisiace. `}<strong parentName="p">{`Povinnými údajmi sú vaše prihlasovacie údaje: meno a e-mailová adresa`}</strong>{`, ktorú využívame na to, aby sme vám vedeli vytvoriť účet inzerenta.`}</p>
    <p>{`Údaje pri vytvorení vášho profilu uchovávame na neurčito. V prípade záujmu o vymazanie, nás kontaktujte na: `}<Email mdxType="Email" />{`.`}</p>
    <h3>{`Údaje pri vytvorení inzercie`}</h3>
    <p>{`Ako registrovaný člen môžete vytvoriť inzerát. Povinnými údajmi, ktoré zadávate do formulára sú:`}</p>
    <ul>
      <li parentName="ul">{`Sektor v ktorom inzerujete`}</li>
      <li parentName="ul">{`Meno kontaktnej osoby alebo vašej firmy`}</li>
      <li parentName="ul">{`Mesto, v ktorom zabezpečujete online predaj alebo službu`}</li>
      <li parentName="ul">{`Stránkové hodiny`}</li>
      <li parentName="ul">{`Popis služby`}</li>
    </ul>
    <p>{`Ostatné údaje vo formulári sú nepovinné.`}</p>
    <p>{`Údaje zadané do inzercie uchovávame do doby, pokiaľ si želáte mať vytvorený konkrétny inzerát. Pokiaľ inzerát vymažete, údaje sa vymažú z databázy.`}</p>
    <h3>{`Novinky`}</h3>
    <p>{`Ak nám k tomu udelíte súhlas, uchovávame si vašu e-mailovú adresu aj za účelom zasielania `}<strong parentName="p">{`informácii o našej webovej stránke`}</strong>{`. Ak nás kontaktujete alebo sa zúčastníte prieskumu, uchovávame si údaje ako vaše meno, kontaktné údaje či správu alebo informáciu, ktorú ste nám doručili alebo oznámili.`}</p>
    <h3>{`Súbory cookies`}</h3>
    <p>{`Na beh webovej stránky sa využívaju služby Google. Návštevou tejto stránky súhlasíte s ukladaním súbor cookies vo vašom prehliadači. Tu nájdete `}<a parentName="p" {...{
        "href": "https://policies.google.com/technologies/cookies"
      }}>{`informácie o tom, ako Google spravuje vaše cookies`}</a>{`. Niektorí návštevníci stránky preferujú nepovoliť cookies. Informácie o tom, `}<a parentName="p" {...{
        "href": "https://policies.google.com/technologies/managing"
      }}>{`ako si sám spravovať súbory cookies`}</a>{`.`}</p>
    <h2>{`III. S kým zdieľame vaše údaje`}</h2>
    <h3>{`Ak to od nás požadujete`}</h3>
    <p>{`Zverejnenie uskutočňujeme v prípadoch, keď využijete komunitné funkcie našej webovej stránky a zverejníte Váš inzerát.`}</p>
    <h3>{`V prípade externého spracovania`}</h3>
    <p>{`Vaše osobné údaje majú k dispozícii niektorí naši dodávatelia. Pri tom dodržiavajú všetky potrebné bezpečnostné, technické a organizačné opatrenia, aby vašim osobným údajom poskytli požadovanú ochranu.`}</p>
    <p>{`Medzi týchto partnerov v súčasnosti patria:`}</p>
    <ul>
      <li parentName="ul">{`Google Ireland Limited : Gordon House, Barrow Street, Dublin 4, Írsko`}</li>
    </ul>
    <h3>{`Z právnych dôvodov a na predchádzanie škodám`}</h3>
    <p>{`Vaše údaje môžeme tiež uchovávať alebo sprístupňovať iným osobám, aby sme vyhoveli povinnostiam vyplývajúcim z právnych predpisov, z požiadaviek štátnych a iných orgánov, aby sme si uplatňovali naše nároky alebo sa obhajovali v konaniach, kde si ich iní uplatňujú voči nám.`}</p>
    <p>{`Medzi kategórie tretích osôb, ktorým sprístupňujeme osobné údaje z týchto dôvodov, patria napríklad súdy, štátne a iné orgány príslušné na výkon kontroly nad našou činnosťou, na riešenie sporov alebo vykonávanie rozhodnutí, či naši právni a účtovní poradcovia a audítori.`}</p>
    <h2>{`IV. Ako chránime vaše údaje`}</h2>
    <p>{`Aby sme zamedzili neoprávnenému prístupu a zneužitiu vašich osobných údajov, zaviedli sme primerané opatrenia ako technického, tak organizačného rázu. Komunikácia medzi vaším zariadením a našimi webovými servermi je šifrovaná. Vaše heslá sú uložené v nečitateľnej forme a všetky vaše dáta sú uložené len na serveroch spoločnosti Google.`}</p>
    <h2>{`V. Aké práva máte nad svojimi údajmi`}</h2>
    <p>{`Ak na tejto webovej stránke máte účet, alebo ste si tu pridali inzerát, máte právo:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`požiadať o export vašich osobných údajov`}</strong>{`: Týka sa to údajov, ktoré o vás ukladáme, včetne údajov, ktoré ste nám poskytli.`}</li>
      <li parentName="ul"><strong parentName="li">{`na prístup k údajom`}</strong>{`: Máte právo získať od nás potvrdenie o tom, či sa o vás spracúvajú osobné údaje a v prípade, ak vaše osobné údaje spracúvame, poskytneme vám informáciu o tom, aké údaje o vás spracúvame, za akým účelom, komu boli vaše osobné údaje poskytnuté, či boli prenesené do tretej krajiny a ako dlho budeme vaše osobné údaje uchovávať.`}</li>
      <li parentName="ul"><strong parentName="li">{`na opravu`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`na vymazanie (právo na zabudnutie)`}</strong>{`: To sa ale netýka údajov, ktoré o vás musíme uchovávať z administratívnych, právnych alebo bezpečnostných dôvodov`}</li>
      <li parentName="ul"><strong parentName="li">{`na obmedzenie spracúvania vašich osobných údajov`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`namietať`}</strong></li>
    </ul>
    <p>{`Uplatniť si vaše práva môžete zaslaním e-mailovej správy na: `}<Email mdxType="Email" />{`.`}</p>
    <h2>{`VI. Podávanie sťažností`}</h2>
    <p>{`V prípade, ak sa domnievate, že spracúvanie vašich osobných údajov našou spoločnosťou je v rozpore s platnými právnymi predpismi na ochranu osobných údajov, máte právo podať sťažnosť na náš hlavný dozorný orgán – `}<a parentName="p" {...{
        "href": "https://dataprotection.gov.sk/uoou/sk/content/kontakt-0"
      }}>{`Úrad na ochranu osobných údajov Slovenskej republiky`}</a>{`.`}</p>
    <p>{`Úrad na ochranu osobných údajov Slovenskej republiky, `}<a parentName="p" {...{
        "href": "https://dataprotection.gov.sk"
      }}>{`https://dataprotection.gov.sk`}</a>{`, Hraničná 12, 820 07 Bratislava 27, tel. číslo: +421.232313214, e-mail: statny.dozor@pdp.gov.sk.`}</p>
    <h2>{`VII. Zmena Zásady ochrany osobných údajov`}</h2>
    <p>{`Obsah stránky Zásady ochrany osobných údajov môžeme upraviť (najmä v prípade technologických zmien na webovej stránke, pridaním novej funkcionality, alebo v prípade zmeny legislatívy). O aktualizácii Zásad ochrany osobných údajov vás budeme informovať prostredníctvom kontaktného emailu, s ktorým sa zaregistrujete na našej webovej stránke. Po obdržaní takéhoto kontaktného emailu a používaním tejto webovej stránky po dátume účinnosti pridaných zmien máme za to, že ste sa oboznámili s aktuálne platnou verziou Zásady ochrany osobných údajov.`}</p>
    <p>{`Posledná aktualizácia: 10.4.2020`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      